import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChartNetwork } from "@fortawesome/pro-regular-svg-icons";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/card";
import SmartResizeSvg from "../images/undraw/undraw_smart_resize_wpn8.svg";
import LogoCloud from "../components/LogoCloud";

const ResourcePerfmanceManagementPage = () => {
  return (
    <Layout>
      {(linkClicked, cardVisible) => (
        <>
          <SEO keywords={[]} title="Resource and Performance Management" />
          <Card
            visible={cardVisible}
            header="Intelligent Resource and Performance Management"
            previousUrl="/offline-capabilities"
            previousLabel="Offline Capabilities"
          >
            <p className="text-base leading-6 text-gray-700 text-left mb-4 sm:mb-5">
              Network conditions, provider traffic quotas and client performance
              restrictions all dictate a sparing way to deal with resources that
              are sent over the internet.
            </p>
            <p className="text-base leading-6 text-gray-700 text-left mb-4 sm:mb-5">
              Here’s what we do to optimize the usage of your network
              connection:
            </p>
            <ul className="text-base leading-6 text-gray-700 text-left mb-4 sm:mb-5 list-disc list-inside">
              <li>
                Use parametric, scalable formats (such as SVG) wherever
                possible.
              </li>
              <li>
                Employ modern media formats and compression algorithms (webp,
                webm, ogg etc) to slim down assets.
              </li>
              <li>
                If possible, take advantage of current browser APIs for live
                generation and processing of media (WebGL, Web Audio API)
              </li>
              <li>
                We’re militant about CSS and JS bundle size and loading
                optimization (resource prefetching, inlining of critical assets
                etc)
              </li>
            </ul>
            <div className="grid gap-6 grid-cols-1">
              <figure className="w-full">
                <img alt="content" src={SmartResizeSvg} />
              </figure>
            </div>
          </Card>
          <LogoCloud
            logos={[
              {
                name: "kreativfarm",
                url: "http://kreativ.farm/",
              },
              { name: "zone", url: "https://zonemedia.at/" },
            ]}
          />
        </>
      )}
    </Layout>
  );
};

export default ResourcePerfmanceManagementPage;
